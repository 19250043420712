.desktop_navDropdownContent__L6fCl {
  animation-duration: 250ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.desktop_navDropdownContent__L6fCl[data-motion='from-start'] {
  animation-name: desktop_enterFromLeft__FaQkD;
}
.desktop_navDropdownContent__L6fCl[data-motion='from-end'] {
  animation-name: desktop_enterFromRight__2EHuM;
}
.desktop_navDropdownContent__L6fCl[data-motion='to-start'] {
  animation-name: desktop_exitToLeft__Qm9NP;
  display: none;
}
.desktop_navDropdownContent__L6fCl[data-motion='to-end'] {
  animation-name: desktop_exitToRight__SH_Fg;
  display: none;
}

.desktop_navViewport__DC1YG {
  height: var(--radix-navigation-menu-viewport-height);
  transform-origin: top center;
  transition: width 300ms ease, height, 300ms ease;
  overflow: hidden;
}

.desktop_navViewport__DC1YG[data-state='open'] {
  animation: desktop_enterIntoNav__eaUTG 500ms ease;
}
.desktop_navViewport__DC1YG[data-state='closed'] {
  animation: desktop_exitFromNav__JTuJf 200ms ease;
}

.desktop_fadeIn___Odlq {
  animation: desktop_fadeIn___Odlq 1000ms ease forwards;
}

@keyframes desktop_fadeIn___Odlq {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.desktop_card__qsuug {
  border-color: transparent;
}

.desktop_card__qsuug:hover {
  border-color: var(--dynamic-color);
}

/* keyframes */
@keyframes desktop_enterIntoNav__eaUTG {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes desktop_exitFromNav__JTuJf {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes desktop_enterFromLeft__FaQkD {
  from {
    transform: translateX(-25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes desktop_enterFromRight__2EHuM {
  from {
    transform: translateX(25px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes desktop_exitToLeft__Qm9NP {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-25px);
    opacity: 0;
  }
}

@keyframes desktop_exitToRight__SH_Fg {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(25px);
    opacity: 0;
  }
}

.__icon__DvM_W {
  transition: all 300ms linear;
}

.__icon__DvM_W.active {
  transform: rotate(-135deg);
  transition: all 300ms linear;
}

.__accordion__juzyv .accordion__content {
  max-height: 0;
  transition: all 0.3s linear;
  overflow: hidden;
}

.__accordion__juzyv .accordion__content--expanded {
  transition: all 0.3s linear;
}

.mobile_hamburger__vITWI {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  padding: 5px 0;
}

.mobile_hamburger__vITWI > span {
  background: white;
  display: block;
  width: 1.5rem;
  height: 2px;
  border-radius: 8px;
  transition: transform 300ms ease;
}

.mobile_hamburger__vITWI > span:nth-child(1) {
  margin-bottom: 5px;
}

.mobile_hamburger__vITWI > span:nth-child(2) {
  margin-bottom: 5px;
}

.mobile_hamburger__vITWI.active > span:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.mobile_hamburger__vITWI.active > span:nth-child(2) {
  visibility: hidden;
}

.mobile_hamburger__vITWI.active > span:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

.mobile_mobileNavDropdown__1CzIQ {
  --nav-header-height: 4.5rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: var(--nav-header-height);
  height: calc(var(--vh, 1vh) * 100 - var(--nav-header-height));
  transform: translateX(-100%);
  transition: transform 500ms ease-in-out, height 500ms ease;
}

.mobile_mobileNavDropdown__1CzIQ.active {
  transform: translateX(0px);
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w,
.mobile_mobileNavDropdown__1CzIQ .accordion__header {
  position: relative;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 34px;
  padding: 16px 24px;
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w::before,
.mobile_mobileNavDropdown__1CzIQ .accordion__header::before {
  content: '';
  background: #ffffff1a;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

.mobile_mobileNavDropdown__1CzIQ .mobile_menuItem__IyF5w {
  color: var(--yellow-40);
}

.mobile_mobileNavDropdown__1CzIQ .accordion__header.active::after {
  background: none;
}

.mobile_mobileNavDropdown__1CzIQ .accordion--lg .accordion__content--expanded {
  max-height: -moz-max-content;
  max-height: max-content;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__icon {
  color: white;
  margin: 0 1rem;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__icon.active {
  rotate: 315deg;
}

.mobile_mobileNavDropdown__1CzIQ .accordion__header.active .mobile_mobileNavTitle___hhxh {
  color: white;
  font-weight: 600;
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_5324ff';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_5324ff';src: local("Arial");ascent-override: 90.20%;descent-override: 22.48%;line-gap-override: 0.00%;size-adjust: 107.40%
}.__className_5324ff {font-family: '__Inter_5324ff', '__Inter_Fallback_5324ff';font-style: normal
}.__variable_5324ff {--font-inter: '__Inter_5324ff', '__Inter_Fallback_5324ff'
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/11d5bc9f0cad36d1-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/cac2ba46e8c8adc9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/242d04bef81519ae-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__interLocal_66fe52';
src: url(/_next/static/media/d080ae18fd04e52c-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__interLocal_Fallback_66fe52';src: local("Arial");ascent-override: 89.79%;descent-override: 22.36%;line-gap-override: 0.00%;size-adjust: 107.89%
}.__className_66fe52 {font-family: '__interLocal_66fe52', '__interLocal_Fallback_66fe52'
}.__variable_66fe52 {--font-inter-local: '__interLocal_66fe52', '__interLocal_Fallback_66fe52'
}

.__logoSlider__I9D_m:nth-child(1) > *:nth-child(-n + 6) {
  display: block;
}

@media (min-width: 640px) {
  .__logoSlider__I9D_m {
    --animation-duration: calc(2s * var(--content-types));
    animation: __marquee__ywa3N linear infinite;
  }

  .__gradientAtMargin__bcALp:hover .__logoSlider__I9D_m {
    animation-play-state: paused;
  }
  .__gradientAtMargin__bcALp::before,
  .__gradientAtMargin__bcALp::after {
    content: '';
    position: absolute;
    width: 64px;
    height: 100%;
    top: 0;
  }
  .__gradientColorAtDark__3RQJp::before {
    background: linear-gradient(90deg, #070425 20%, #07042500 100%);
    z-index: 10;
    left: 0px;
  }
  .__gradientColorAtDark__3RQJp::after {
    background: linear-gradient(270deg, #070425 0%, #07042500 100%);
    right: 0px;
  }
  .__gradientColorAtDefault__7PW6R::before {
    background: linear-gradient(90deg, #2c0d62 20%, #2c0d6200 100%);
    z-index: 10;
    left: 0px;
  }
  .__gradientColorAtDefault__7PW6R::after {
    background: linear-gradient(270deg, #2c0d62 0%, #2c0d6200 100%);
    right: 0px;
  }

  .__logoSlider__I9D_m:nth-child(1) > *:nth-child(-n + 6) {
    display: hidden;
  }
}

@keyframes __marquee__ywa3N {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.__accordion__SG7s2 .active {
  font-weight: 500;
}
.__accordion__SG7s2 .accordion__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.__accordion__SG7s2 .accordion__title {
  font-family: var(--font-articulat-cf);
  color: white;
  font-size: 14px;
  line-height: 1.3;
  font-weight: 500;
}

.__accordion__SG7s2 .active.accordion__header .accordion__title {
  font-weight: 600;
}

.__icon__8rj2x {
  transition: all 300ms linear;
}

.__icon__8rj2x.active {
  transform: rotate(-180deg);
  transition: all 300ms linear;
}

@media (min-width: 768px) {
  .__accordion__SG7s2 .accordion__title {
    font-size: 24px;
    line-height: 1.2;
  }
}

.__accordion__SG7s2 .accordion__content {
  border-bottom: 1px solid rgba(0, 176, 204, 0.13);
  transition: all 0.5s linear;
  overflow: hidden;
}
.__accordion__SG7s2 .accordion__content--expanded {
  border-bottom: 3px solid var(--cyan-70);
  transition: all 0.5s linear;
}
.__accordion__SG7s2:last-child .accordion__content {
  border-bottom: none;
}

.__scheduleList__1sxHr {
  animation: __fade__jiQac 800ms ease;
}

@keyframes __fade__jiQac {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}
@media (min-width: 768px) {
  .__accordion__SG7s2 .active.accordion__header .accordion__title {
    font-weight: 600;
  }
  .__accordion__SG7s2 .active.accordion__header {
    margin-bottom: 0.5rem;
    transition: all 0.4s linear;
  }
  .__accordion__SG7s2 .accordion__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    transition: all 0.4s linear;
  }
}

.__contentContainer__gZhmd {
  animation: __fade-in__SAyrG 500ms ease forwards;
}

@keyframes __fade-in__SAyrG {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  25% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.__sideLineFadeIn__U7aGN {
  animation: __sidelineAnimationIn__wnG3b 800ms ease forwards;
}

@keyframes __sidelineAnimationIn__wnG3b {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.__sideLineFadeOut__h1WaF {
  animation: __sidelineAnimationOut__hKQio 800ms ease forwards;
}

@keyframes __sidelineAnimationOut__hKQio {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.__fadeIn__JT0Zz {
  animation: __fadeIn__JT0Zz 2000ms ease forwards;
}

@keyframes __fadeIn__JT0Zz {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.__fadeOut__hAb2l {
  animation: __fadeOut__hAb2l 1000ms ease forwards;
}

@keyframes __fadeOut__hAb2l {
  0%,
  15% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

.__animatedLine__RCHPi {
  transition: height 0.5s ease;
}

.__animatedLine__RCHPi.__visible__3Fqu1 {
  height: 100%;
}

.__animatedLine__RCHPi.__hidden__KDHcs {
  height: 0;
}

.__hoverBorder__N1z3C {
  position: relative;
}

.__hoverBorder__N1z3C::after,
.__activeBorder__193lJ::after {
  content: '';
  position: absolute;
  height: 4px;
  bottom: 0;
  background: var(--green-40);
}

.__hoverBorder__N1z3C::after {
  width: 0%;
  left: 50%;
  transition: all 300ms ease-in-out;
}

.__activeBorder__193lJ::after {
  width: 100%;
  left: 0%;
}

.__hoverBorder__N1z3C:hover::after {
  left: 0%;
  width: 100%;
}

.__animate__Z07u_ {
  animation: __animate__Z07u_ 500ms ease-in;
}

@keyframes __animate__Z07u_ {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.__animateImage__uTuYA {
  animation: __animateImage__uTuYA 800ms ease-in;
}

@keyframes __animateImage__uTuYA {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

.__contentContainer__qg7j_ {
  animation: __fade-in__wp_Q_ 500ms ease forwards;
}

@keyframes __fade-in__wp_Q_ {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  25% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.__sideLineFadeIn__0q2TM {
  animation: __sidelineAnimationIn__64GBp 800ms ease forwards;
}

@keyframes __sidelineAnimationIn__64GBp {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.__sideLineFadeOut__VV6Qu {
  animation: __sidelineAnimationOut__HZcUV 800ms ease forwards;
}

@keyframes __sidelineAnimationOut__HZcUV {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.__fadeIn__Dp564 {
  animation: __fadeIn__Dp564 2000ms ease forwards;
}

@keyframes __fadeIn__Dp564 {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.__fadeOut__eoNqC {
  animation: __fadeOut__eoNqC 1000ms ease forwards;
}

@keyframes __fadeOut__eoNqC {
  0%,
  15% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

.__animatedLine__jxGI5 {
  transition: height 0.5s ease;
}

.__animatedLine__jxGI5.__visible__ZeoU0 {
  height: 100%;
}

.__animatedLine__jxGI5.__hidden__nM3VX {
  height: 0;
}

.__contentContainer__8iNye {
  animation: __fade-in__r4RtZ 500ms ease forwards;
}

@keyframes __fade-in__r4RtZ {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  25% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.__sideLineFadeIn__Odx7u {
  animation: __sidelineAnimationIn__39fLp 800ms ease forwards;
}

@keyframes __sidelineAnimationIn__39fLp {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

.__sideLineFadeOut__FDtb1 {
  animation: __sidelineAnimationOut__oEnla 800ms ease forwards;
}

@keyframes __sidelineAnimationOut__oEnla {
  0% {
    height: 100%;
  }
  100% {
    height: 0%;
  }
}

.__fadeIn__RDonV {
  animation: __fadeIn__RDonV 2000ms ease forwards;
}

@keyframes __fadeIn__RDonV {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  85%,
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.__fadeOut__9ObI1 {
  animation: __fadeOut__9ObI1 1000ms ease forwards;
}

@keyframes __fadeOut__9ObI1 {
  0%,
  15% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

.__animatedLine__tbGdE {
  transition: height 0.5s ease;
}

.__animatedLine__tbGdE.__visible___ilem {
  height: 100%;
}

.__animatedLine__tbGdE.__hidden__BJm_s {
  height: 0;
}

/* default style */
.__button__Lt6ZG {
  transition: color 300ms ease-in, background-color 300ms ease-in;
}

.__rounded__FtSyw {
  border-radius: 9999px;
}

/* button size */
.__small__iFt6k {
  min-height: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__standard__wAJgL {
  min-height: 48px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__large__LCAHh {
  min-height: 56px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* button type */
.__primary__nkixA {
  background: var(--blue-50);
  color: var(--white);
}

.__primary__nkixA:hover {
  background: var(--blue-70);
}

.__secondary__svK_8 {
  background: var(--blue-10);
  color: var(--blue-50);
}

.__secondary__svK_8:hover {
  background: var(--blue-50);
  color: var(--white);
}

.__tertiary__8gm2N {
  background: var(--yellow-50);
  color: var(--gray-90);
}

.__tertiary__8gm2N:hover {
  background: var(--yellow-60);
}

.__pill__A8aQU {
  background: transparent;
  color: var(--gray-90);
  font-size: 13px;
  height: 26px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: auto;
  padding-top: 2px;
  padding-bottom: 2px;
}

.__pill__A8aQU:hover {
  background: var(--gray-10);
}

.__pill__A8aQU.active {
  background: var(--gray-90);
  color: var(--white);
}

.__orangePill__LJ1_W {
  color: var(--orange-50);
  padding-left: 16px;
  padding-right: 16px;
  min-height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__orangePill__LJ1_W:hover {
  background: var(--orange-10);
}

.__orangePill__LJ1_W.active {
  background: var(--orange-50);
  color: var(--white);
}

.__yellowPill__TxqFw {
  color: var(--yellow-30);
  border: 1px solid var(--yellow-30);
  padding-left: 44px;
  padding-right: 44px;
  min-height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
}

.__yellowPill__TxqFw:hover {
  background: var(--yellow-30);
  color: var(--blue-web-50);
}

.__yellowPill__TxqFw.active {
  background: var(--yellow-30);
  color: var(--blue-web-50);
}

/* button disabled */
.__button__Lt6ZG:disabled {
  cursor: not-allowed;
  background: var(--gray-40);
  color: var(--gray-10);
}

.__button__Lt6ZG:disabled:hover {
  background: var(--gray-40);
  color: var(--gray-10);
}

/* loading prop */
.__loader__WjFTb {
  animation: __loading__eWU1p 1.4s infinite linear;
  transform: translateZ(0);
}

@keyframes __loading__eWU1p {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.text_input__qPsVg {
  transition: 0.3s border-color;
}

.text_input__qPsVg:hover,
.text_input__qPsVg:active,
.text_input__qPsVg:focus {
  background-color: var(--white);
  border: 1px solid var(--blue-50);
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.text_input_cmp_form__GcaMF {
  border: none;
  background-color: #1c183a !important;
  color: #fff;
  padding: 0 16px;
}

.text_input_cmp_form__GcaMF:hover,
.text_input_cmp_form__GcaMF:active,
.text_input_cmp_form__GcaMF:focus {
  background-color: #1c183a !important;
  border: none !important;
  box-shadow: none;
  outline: none;
}

.phone_input__yz6PS {
  transition: 0.3s border-color;
}

.phone_input__yz6PS:hover,
.phone_input__yz6PS:active,
.phone_input__yz6PS:focus {
  background: var(--white);
  border: 1px solid var(--blue-50);
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.phone_select_container__znjD4 {
  max-width: 88px;
  transition: 0.3s border-color;
}

.phone_select_input__kk8xw {
  border: 1px solid var(--gray-40);
  border-right: 0;
  border-radius: 8px 0 0 8px;
  width: 100%;
  height: 48px;
  padding: 0 1rem;
}

.phone_select_input__kk8xw:hover,
.phone_select_input__kk8xw:active,
.phone_select_input__kk8xw:focus {
  background: var(--white);
  border: 1px solid var(--blue-50);
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.phone_select_options__3ni7y {
  border: 1px solid var(--gray-40);
  border-radius: 4px;
  background: white;
  position: absolute;
  max-width: 88px;
  width: 100%;
  max-height: 250px;
  overflow: auto;
}

.phone_select_option__aXnIs {
  padding: 0.5rem 1rem;
}

.selectDark_select__MI_k3 {
  background: #1c183a !important;
  color: #fff;
  margin-top: 0.25rem;
  position: absolute;
  overflow-y: auto;
  max-height: 500px;
  width: 100%;
  z-index: 10;
}

.selectDark_input__fg_At {
  border-radius: 8px;
  background: #1c183a !important;
  color: #fff;
  border: none;
  padding: 0 16px;
  min-height: 40px;
  width: 100%;
  transition: 0.3s border-color;
}

.selectDark_input__fg_At:hover,
.selectDark_input__fg_At:active,
.selectDark_input__fg_At:focus {
  border-radius: 8px;
  outline: none;
  background-color: #1c183a;
  border: none !important;
  box-shadow: none;
}

.selectDark_options__idllt {
  list-style: none;
  overflow: auto;
}

.selectDark_row___HVYa {
  display: block;
}

.selectDark_option__nnFFP {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 14px;
  padding: 0 16px;
  min-height: 32px;
  outline: none;
  width: 100%;
  text-align: left;
}

.selectDark_option__nnFFP.selectDark_is-selected__UliRQ {
  background: var(--blue-10);
  color: var(--blue-50);
  font-weight: 500;
}

.selectDark_option__nnFFP.selectDark_is-selected__UliRQ::after {
  content: '';
  background-image: url('/assets/icons/form/select/tick.svg');
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.select_select__EhoJx {
  background: white;
  margin-top: 0.25rem;
  position: absolute;
  overflow-y: auto;
  max-height: 350px;
  width: 100%;
  z-index: 10;
  transition: opacity 150ms ease-in;
}

.select_search_container__wpI58 {
  position: relative;
}

.select_search_container__wpI58::after {
  content: '';
  background: url('/assets/icons/form/select/zoom.svg');
  position: absolute;
  right: 1rem;
  top: 11px;
  width: 16px;
  height: 16px;
}

.select_container__NbNtD:not(.select_has-focus__4zru_) .select_select__EhoJx {
  visibility: hidden;
  opacity: 0;
}

.select_input__uQtk2 {
  border-radius: 8px;
  background: var(--gray-10);
  border: 1px solid var(--gray-20);
  padding: 0 16px;
  min-height: 40px;
  width: 100%;
  transition: 0.3s border-color;
}

.select_input__uQtk2:hover,
.select_input__uQtk2:active,
.select_input__uQtk2:focus {
  background: var(--white);
  border: 1px solid var(--blue-50);
  border-radius: 8px;
  box-shadow: 0px 0px 0px 4px rgba(22, 0, 229, 0.05);
  outline: none;
}

.select_options__GQA4n {
  list-style: none;
  overflow: auto;
}

.select_row__TVCfd {
  display: block;
}

.select_group__HllKz {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--gray-30);
}

.select_group-header__GlsBU {
  color: var(--gray-60);
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  font-size: 11px;
  height: 32px;
  padding: 0 1rem;
  text-transform: uppercase;
}

.select_option__UwKDG {
  background-color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: block;
  position: relative;
  font-size: 14px;
  padding: 0 16px;
  min-height: 32px;
  outline: none;
  width: 100%;
  text-align: left;
}

.select_option__UwKDG.select_is-selected__krAGn {
  background: var(--blue-10);
  color: var(--blue-50);
  font-weight: 500;
}

.select_option__UwKDG.select_is-selected__krAGn::after {
  content: '';
  background-image: url('/assets/icons/form/select/tick.svg');
  background-repeat: no-repeat;
  height: 12px;
  width: 12px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.__toastRoot__WPyLL[data-state='open'] {
  animation: __slideIn__yc7qK 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.__toastRoot__WPyLL[data-state='closed'] {
  animation: __hide__ZY1_u 100ms ease-in;
}

.__toastRoot__WPyLL[data-swipe-direction='down'] {
  position: fixed;
  width: 100%;
  bottom: 8%;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  animation: __slideIn__yc7qK 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.__toastRoot__WPyLL[data-swipe-direction='up'] {
  position: fixed;
  width: 100%;
  top: 8%;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  animation: __slideDown__fvwV0 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

@keyframes __hide__ZY1_u {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideIn__yc7qK {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes __slideDown__fvwV0 {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.__registerBtn___pGAa {
  color: var(--blue-web-90);
  background-color: var(--yellow-60);
}
.__registerBtn___pGAa:hover {
  background-color: var(--yellow-50);
}

.__formField__svlfB {
  align-items: unset;
  width: 100%;
}

.__formField__svlfB label {
  color: var(--blue-web-90);
  font-size: 16px !important;
  font-weight: 500;
}

.__formField__svlfB input {
  color: black;
  height: 3rem;
  border-color: var(--gray-40);
}

.__formField__svlfB input::-moz-placeholder {
  color: var(--gray-50);
}

.__formField__svlfB input::placeholder {
  color: var(--gray-50);
}

